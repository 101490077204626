.button {
    @apply px-2 py-1 rounded cursor-pointer shadow-2xl;

    &.button-blue-invert {
        @apply bg-transparent text-blue-500;

        &.is-active {
            @apply bg-blue-500 text-white;
        }

        &:hover {
            @apply bg-blue-500 text-white;
        }

        &:focus {
            @apply bg-blue-500 text-white;
        }
    }

    &.button-light-blue-invert {
        @apply bg-transparent text-teal-700;

        &.is-active {
            @apply bg-teal-700 text-white;
        }

        &:hover {
            @apply bg-teal-700 text-white;
        }

        &:focus {
            @apply bg-teal-700 text-white;
        }
    }

    &.button-green-invert {
        @apply bg-transparent text-green-700;

        &.is-active {
            @apply bg-green-700 text-white;
        }

        &:hover {
            @apply bg-green-700 text-white;
        }

        &:focus {
            @apply bg-green-700 text-white;
        }
    }

    &.button-purple-invert {
        @apply bg-transparent text-purple-700;

        &.is-active {
            @apply bg-purple-700 text-white;
        }

        &:hover {
            @apply bg-purple-700 text-white;
        }

        &:focus {
            @apply bg-purple-700 text-white;
        }
    }

    &.button-yellow-invert {
        @apply bg-white text-yellow-200;

        &.is-active {
            @apply bg-yellow-200 text-white;
        }

        &:hover {
            @apply bg-yellow-200 text-white;
        }

        &:focus {
            @apply bg-yellow-200 text-white;
        }
    }

    &.button-brown-invert {
        @apply bg-white text-brown-700;

        &.is-active {
            @apply bg-brown-500 text-white;
        }

        &:hover {
            @apply bg-brown-700 text-white;
        }

        &:focus {
            @apply bg-brown-700 text-white;
        }
    }

    &.button-teal-invert {
        @apply bg-white text-teal-500;

        &.is-active {
            @apply bg-teal-500 text-white;
        }

        &:hover {
            @apply bg-teal-500 text-white;
        }

        &:focus {
            @apply bg-teal-500 text-white;
        }
    }

    &.is-primary {
        @apply bg-blue-500 text-white;

        &:hover {
            @apply bg-blue-700 bg-opacity-75;
        }
        &:focus {
            @apply bg-blue-700;
        }
    }

    &.is-green {
        @apply bg-green-500 text-white;

        &:hover {
            @apply bg-green-700 bg-opacity-75;
        }

        &:focus {
            @apply bg-green-700;
        }
    }

    &.is-secondary {
        @apply bg-teal-500 text-white;

        &:hover {
            @apply bg-teal-700 bg-opacity-75;
        }
        &:focus {
            @apply bg-teal-700;
        }
    }

    &.is-danger {
        @apply bg-red-500 text-white;

        &:hover {
            @apply bg-red-700 bg-opacity-75;
        }
        &:focus {
            @apply bg-red-700;
        }
    }

    &.is-archive {
        @apply bg-gray-500 text-white;

        &:hover {
            @apply bg-gray-700 bg-opacity-75;
        }
        &:focus {
            @apply bg-gray-700;
        }
    }
}

kbd {
    @apply inline-block border-gray-500 rounded mx-1 px-2 py-1 shadow bg-gray-50 text-xs font-mono;
}

ul > li.list-link {
    @apply block text-blue-700 px-2;

    a {
        &:hover {
            @apply text-gray-500 font-medium;
        }
    }
}

.nav-link {
    @apply block text-blue-700 px-2 text-xl;

    &:hover {
        @apply text-gray-500;
    }
}

.nav-link-alt {
    @apply block text-gray-500 px-2;

    &:hover {
        @apply text-blue-700;
    }
}

.button-link {
    @apply text-blue-700 cursor-pointer;

    &:hover {
        @apply text-blue-900;
    }
    &:focus {
        @apply text-blue-900;
    }
}

.button-naked {
    @apply text-blue-700 cursor-pointer font-black;

    &:hover {
        @apply text-blue-200;
    }
    &:focus {
        @apply text-blue-200;
    }
}
