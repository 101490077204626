.tooltip {
    @apply rounded inline-flex whitespace-nowrap text-center text-sm relative bg-opacity-75;

    &:after {
        @apply bg-gray-200 border-gray-200 rounded text-white hidden p-1 absolute text-center z-50 shadow-xl;
    }

    &:before {
        @apply bg-gray-200 hidden absolute w-3 h-3 z-50;
        content: ' ';
    }

    &:hover {

        &:after {
            @apply inline-block;
        }

        &:before {
            @apply inline-block;
        }
    }

    &.tooltip-top {
        &:after {
            content: attr(data-tooltip);
            top: 0;
            left: 50%;
            transform: translate(-50%, calc(-100% - 10px));
        }

        &:before {
            top: 0;
            left: 50%;
            transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
        }
    }

    &.tooltip-right {
        &:after {
            content: attr(data-tooltip);
            top: 0;
            right: 0;
            transform: translateX(calc(100% + 10px));
        }

        &:before {
            top: 50%;
            right: 0;
            transform: translate(calc(100% + 5px), -50%) rotate(45deg);
        }
    }

    &.tooltip-bottom {
        &:after {
            content: attr(data-tooltip);
            bottom: 0;
            left: 50%;
            transform: translate(-50%, calc(100% + 10px));
        }

        &:before {
            bottom: 0;
            left: 50%;
            transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
        }
    }

    &.tooltip-left {

        &:after {
            content: attr(data-tooltip);
            top: 0;
            left: 0;
            transform: translateX(calc(-100% - 10px));
        }

        &:before {
            top: 50%;
            left: 0;
            transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
        }

    }
}

