$fa-font-path: '/fonts/font-awesome';

// Tailwind
@tailwind base;
@tailwind components;

// Components
@import 'cloak';
@import 'buttons';
@import 'tooltips';
@import 'textures';
@import 'datetime';
@import 'icons';

@tailwind utilities;
@tailwind screens;

// Font Awesome
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/brands';

@import 'stateface';

