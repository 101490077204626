.icon::before {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.icon-sonar::before {
	content: url('img/sonar.png');
}
